import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [personas, setPersonas] = useState([]);

  useEffect(() => {
    fetch('https://www.max.korindex.com/personas')
      .then(response => response.json())
      .then(data => setPersonas(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src="logo.svg" className="App-logo" alt="logo" />
        <h1>Personas</h1>
        <table className="personas-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Edad</th>
            </tr>
          </thead>
          <tbody>
            {personas.map(persona => (
              <tr key={persona.id}>
                <td>{persona.id}</td>
                <td>{persona.nombre}</td>
                <td>{persona.apellido}</td>
                <td>{persona.edad}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </header>
    </div>
  );
}

export default App;
